$(document).ready(function(){
    var reveal = {
        delay    : 100,
        duration : 400,
        distance : '120px',
        easing   : 'ease-in-out',
    };

    var revealSlow = {
        delay    : 300,
        duration : 400,
        distance : '120px',
        easing   : 'ease-in-out',
    };

    var revealLeft = {
        origin : 'left',
        delay    : 200,
        duration    : 700,
        distance : '120px',
        easing   : 'ease-in-out',
    };

    var revealright = {
        origin : 'right',
        delay    : 200,
        duration    : 700,
        distance : '120px',
        easing   : 'ease-in-out',
    };

    window.sr = ScrollReveal();

    sr.reveal('.reveal-1', revealright);
    sr.reveal('.reveal-2', revealLeft);
    sr.reveal('.reveal-3', revealSlow);

    scrollBanner();

    $(document).scroll(function () {
        scrollBanner();
    });


});

function scrollBanner() {
    scrollPos = $(this).scrollTop();
    if($('.paralaxx1').length != 0) {
        $('.paralaxx1').css({
            'transform': 'matrix(1, 0, 0, 1, 0,' +(scrollPos/ -20)+')'
        });
    }
}

